export const termsConditions = [
  { value: 'scope', label: 'Scope' },

  { value: 'userRights', label: 'User rights' },
  { value: 'customerResponsibilities', label: 'Customer responsibilities' },
  { value: 'systemAvailability', label: 'System availability' },
  { value: 'support', label: 'Support' },
  { value: 'renumerations', label: 'Remunerations' },
  {
    value: 'intellectualPropertyRights',
    label: 'Intellectual property rights',
  },
  { value: 'dataProtection', label: 'Data protection and data security' },
  { value: 'disclaimerLiability', label: 'Disclaimer of liability' },
  { value: 'communication', label: 'Communication' },
  { value: 'amandments', label: 'Amendments' },
  { value: 'severabilityClause', label: 'Severability clause' },
  { value: 'jurisdiction', label: 'Jurisdiction' },
];

export const privacyPolicy = [
  {
    value: 'childrenUnderAge',
    label: 'Children under 18',
    content: ['p1'],
  },
  {
    value: 'informationWeCollect',
    label: 'Information we collect and how we collect it',
    content: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8'],
  },
  {
    value: 'informationYouProvide',
    label: 'Information you provide to use',
    content: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'],
  },
  {
    value: 'informationWeCollectThroughAutomaticData',
    label:
      'Information we collect through automatic data collection technologies',
    content: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'p11',
    ],
  },
  {
    value: 'informationVoluntarily',
    label: 'Information voluntarily provided for the use of services',
    content: ['p1', 'p2', 'p3', 'p4'],
  },
  {
    value: 'useOfCookies',
    label: 'Use of cookies by third parties',
    content: ['p1'],
  },
  {
    value: 'howWeUseYourInformation',
    label: 'How we use your information',
    content: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'p11',
      'p12',
    ],
  },
  {
    value: 'saleOfPersonalInformation',
    label: 'Sale of personal information',
    content: ['p1'],
  },
  {
    value: 'disclosureOfYourInformation',
    label: 'Disclosure of your information',
    content: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'p11',
      'p12',
    ],
  },
  {
    value: 'informationRights',
    label: 'Your information rights and controls',
    content: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'p11',
    ],
  },
  {
    value: 'exerciseRights',
    label: 'How to exercise your rights with us',
    content: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'p11',
    ],
  },
  {
    value: 'dataStorage',
    label: 'Data storage and deletion',
    content: ['p1'],
  },
  {
    value: 'deletion',
    label: 'Deletion',
    content: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10'],
  },
  {
    value: 'changes',
    label: 'Changes to our privacy policy',
    content: ['p1'],
  },
  {
    value: 'contantInformation',
    label: 'Contact information',
    content: ['p1', 'p2'],
  },
];

export const agb = [
  {
    value: 'scopeOfApp',
    label: 'Scope of application',
    content: ['p1', 'p2', 'p3'],
  },
  {
    value: 'complience',
    label: 'Compliance',
    content: ['p1'],
  },
  {
    value: 'rightOfUse',
    label: 'Right of use',
    content: ['p1', 'p2', 'p3'],
  },
  {
    value: 'yourAccount',
    label: 'Your account',
    content: ['p1'],
  },
  {
    value: 'payment',
    label: 'Payment',
    content: ['p1'],
    subContent: ['subP1', 'subP2', 'subP3'],
  },
  {
    value: 'termination',
    label: 'Termination',
    content: ['p1'],
  },
  {
    value: 'support',
    label: 'Support',
    content: ['p1'],
  },
  {
    value: 'intellectualPropertyRights',
    label: 'Intellectual Property Rights',
    content: ['p1'],
  },
  {
    value: 'confidentiality',
    label: 'Confidentiality',
    content: ['p1', 'p2', 'p3', 'p4'],
  },
  {
    value: 'dataProtectionAndSecurity',
    label: 'Data Protection and Security',
    content: ['p1', 'p2'],
  },
  {
    value: 'disclaimer',
    label: 'Disclaimer',
    content: ['p1'],
  },
  {
    value: 'applicableLaw',
    label: 'Applicable Law',
    content: ['p1'],
  },
  {
    value: 'jurisdiction',
    label: 'Jurisdiction',
    content: ['p1'],
  },
];
